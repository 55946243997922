
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '../services/common.service';
import { ApiCallService } from '../services/api-call.service';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { data } from 'jquery';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  hide = true;
  loginForm: FormGroup;
  api_key = 'YOUR API KEY';
  url = 'https://ipgeolocation.abstractapi.com/v1/?api_key=' + this.api_key;

  constructor(public fb: FormBuilder, private router: Router, private commanService: CommonService, private apiService: ApiCallService) {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required]],
      phone_number: ['', [Validators.required]],
    })
  }
  ngOnInit() {
    document.body.style.backgroundColor = "#E5E7E9";
  }

  ngOnDestroy() {
    document.body.style.background = '#fff';
  }
  submitForm() {
    sessionStorage.setItem('token', 'dummy_token');
    if (this.loginForm.valid) {
      var param = this.loginForm.value;
      var data = { data: this.loginForm.value, param: param }
      this.apiService.loginEmp(data).subscribe({
        next: async (result: any) => {
          console.log("Login Details:", result);
          if (result.data) {
            if (result.data.email === this.loginForm.value.email && result.data.phone_number === this.loginForm.value.phone_number) {
              this.commanService.showSuccess('Login Successful!');
               this.router.navigate(['components']);
              window.localStorage.setItem('user', JSON.stringify(result));
             
            } else {
              this.commanService.showError("Please check username or password!");
            }

          } else {
            this.commanService.showError("User Inactive Contact Admin!");
          }
        }, error: (error: any) => {
          console.log(error)
          this.commanService.showError(error);
        }
      })
      // );
    } else {
      this.commanService.showError('Fill out all the details');
    }

  }

  }




