<div class="container">


    <mat-card class="col-md-4 mat-card-login">
        <img class="img-login-logo"[routerLink]="['/home']" width="100px" src="https://res.cloudinary.com/nextgenedu/image/upload/v1704112095/Sahyadri_logo_b4h5bt.png">
        <mat-divider></mat-divider>
        <mat-card-title class="mt-4 text-center">Sign in to your account</mat-card-title>
        <mat-card-content>
            <form *ngIf="loginForm" [formGroup]="loginForm" (ngSubmit)="submitForm()">
                <div class="row">
                    <mat-form-field>
                        <span matPrefix><mat-icon class="input-prefix-mat-icon">mail_outline</mat-icon>
                            &nbsp;&nbsp;</span>
                        <input required type="text" matInput placeholder="Email ID" formControlName="email" />
                        <mat-error>Please provide a valid username</mat-error>
                    </mat-form-field>
                </div>

                <div class="row mt-2">
                    <mat-form-field>
                        <span matPrefix><mat-icon class="input-prefix-mat-icon">lock_outline</mat-icon>
                            &nbsp;&nbsp;</span>
                        <input type="password" [type]="hide ? 'password' : 'text'" matInput placeholder="Password"
                            formControlName="phone_number" />
                        <button style="font-size: 18px;" mat-icon-button matSuffix (click)="hide = !hide"
                            [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                            <mat-icon class="input-prefix-mat-icon">{{hide ? 'visibility_off' :
                                'visibility'}}</mat-icon>
                        </button>
                        <mat-error>Please provide a valid password</mat-error>
                    </mat-form-field>
                </div>


                <div class="row mt-2">
                    <div class="col-md-5">
                    </div>
                    <div class="col-md-7">
                        <a>Forget Your Password?</a>
                    </div>
                </div>

                <div class="row mt-3">
                    <div class="col-md-4">
                        <!-- <button class="mat-button-gradient" style="float: right;"mat-button>Home</button> -->
                        <button class="mat-button-gradient" style="float: left;"mat-button [routerLink]="['/home']" >
                            <mat-icon>home</mat-icon>Home
                          </button>
                          
                    </div>
                    <div class="col-md-4">
                        <mat-checkbox style="float: left; color: #7F8C8D; " class="example-margin">Remember
                            me</mat-checkbox>
                    </div>
                   
                    <div class="col-md-4">
                        <button class="mat-button-gradient" style="float: right;" type="submit"
                            mat-button>Login</button>
                    </div>
                </div>
            </form>
        </mat-card-content>
    </mat-card>


</div>