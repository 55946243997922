
<!-- navbar.component.html -->

<mat-toolbar class="navbar-toolbar">
  <span class="logo-container"><img class="logow"
      src="https://res.cloudinary.com/dnxxp9iwj/image/upload/v1704875247/sahyadrifleet.com/Sahyadri_logo_b4h5bt_gweonq.png"
      alt=""></span>
      <button mat-icon-button *ngIf="isAndroid" (click)="toggleNavbar()" class="menu-icon" [ngClass]="{ 'show': !isNavbarOpen }">
        <mat-icon>menu</mat-icon>
      </button>
  <div class="navbar-links" [ngClass]="{ 'show': !isNavbarOpen, 'hide-mobile': isNavbarOpen }">
    <a class="nav-link" href="#home">Home </a>
    <a class="nav-link" href="#aboutus">About Us</a>
    <a class="nav-link" href="#services"> Services</a>
    <a class="nav-link " href="#vtp">VTS</a>
    <a class="nav-link " href="#contactus">Contact Us</a>
    <a class="nav-link " [routerLink]="['/login']">Login</a>

    
  </div>
</mat-toolbar>




<section class="section1" id="home">

  <div class="marquee-container">
    <h1 class="marquee-text">Welcome to Sahyadri Fleet Technology Solution </h1>
  </div>
</section>

<!-- ////////////////////// -->
<div class="container mt-5">
  <div class="row">
    <h2><span style="color: #ff6600;">Driving innovation.</span> <br>Revolutionizing
      office commute.</h2>

    <div class="col-md-6">
      <hr>
      <p>At Sahyadri Fleet Technology Solution , we understand the unique difficulties faced by each company in
        managing employee commutes. This motivates us to offer customized solutions tailored to your specific needs.
      </p>
      <p>

        Our Sahyadri Fleet Technology Solution offering allows enterprises to outsource their transportation
        operations. By combining technology, fleet management, and people, Sahyadri Fleet Technology Solution is a
        one-stop shop for all your employee commute needs.</p>


      <p> Our Sahyadri Fleet Technology Solution , is powered by intelligent technology and is designed to identify
        and mitigate risks, empower ESG compliance, and improve employee experience.
      </p>
      <p>



        Whether you have a small team or a large workforce, we have the expertise to upgrade your employee
        transportation. Take a demo now to find out what helps us provide safe commuting options that effortlessly
        balance comfort and affordability.

      </p>
      <!-- <button type="button" class="btn btn-primary">Request a demo</button> -->
    </div>

    <div class="col-md-6">
      <img width="100%"
        src="https://res.cloudinary.com/nextgenedu/image/upload/v1703966806/getty_159406920_2000133320009280405_392896_ka7rqh.jpg"
        alt=""> <!-- <img fetchpriority="high" decoding="async" width="591" height="608"
          src="https://moveinsync.com/wp-content/uploads/2023/04/GIF_2-min.gif"
          class="attachment-full size-full wp-image-12501" alt="" title="GIF2min MoveInSync MoveInSync"> -->

    </div>
  </div>
</div>
<div class="container mt-5" id="services">
  <h2><span style="color: #ff6600;">RENTAL SERVICES</span> <br>OUTSTATION & LOCAL</h2>
  <hr>
  <div class="row">

    <div class="col-md-6">
      <img width="100%"
        src="https://res.cloudinary.com/nextgenedu/image/upload/v1703966805/1_eKWCsiR4lRe6Wnz2BUQ7BA_qjjqwd.jpg" alt="">
    </div>
    <div class="col-md-6">

      <p>If its luxury that you desire and if you want to experience your dream destination in your dream car we would
        be eager to help you achieve that, just let us know which set of wheels suits your fancy. Our car rental
        service at Pune, India has cars from across the scale, from the simple to the luxurious</p>


      <p> Sahyadri has more than 500 vehicles to provide transportation to employees across the Pune – Hinjewadi,
        Wakad, Rajgurunagar, Talegaon, Wagholi, Ranjangaon and Solapur road ensuring a reliable, safe and economic
        travel solution for employees.</p>
    </div>

  </div>
  <br>
</div>
<!-- ///////////////////// -->

<!-- //////////////////// -->
<div class="container mt-5">

  <div class="row">
    <div class="col-md-3">
      <mat-icon>done</mat-icon> <span>Our Commitment</span>
    </div>
    <div class="col-md-3">
      <mat-icon>sentiment_satisfied_alt</mat-icon>
      Customer Satisfaction
    </div>
    <div class="col-md-2">
      <mat-icon>lightbulb</mat-icon> Innovation
    </div>
    <div class="col-md-2">
      <mat-icon>people</mat-icon> Community
    </div>
    <div class="col-md-2"> <mat-icon>eco</mat-icon> Environment

    </div>
  </div>
</div>
<!-- ////////////// -->
<div class="container mt-5">
  <div class="row">
    <div class="col-md-12" #office>
      <h2><span style="color: #ff6600;">Redefining</span> <br>office commute
      </h2>
      <hr>
      <p style="text-align: center;">With years of experience, we have redefined employee transportation in multiple
        major cities across the world. Our end-to-end outsourcing service as well as our technology solutions are
        built to support organizations as they enhance their employee commute processes.</p>


    </div>
  </div>
</div>

<!-- /////////////// -->
<div class="container" id="aboutus">
  <div class="row">
    <div class="col-md-4 mt-3">
      <div class="card shadow">
        <div class="card-body">
          <h5 class="card-title"> <mat-icon class="icon">security</mat-icon>

            Safe</h5>
          <p class="card-text">We are dedicated to revolutionizing the way people commute through secure
            transportation solutions</p>
        </div>
      </div>
    </div>
    <div class="col-md-4 mt-3">
      <div class="card shadow">
        <div class="card-body">
          <h5 class="card-title"> <mat-icon class="icon">sync_alt</mat-icon>

            Reliable</h5>
          <p class="card-text">We make sure your employees are always on time, ensuring they never lose on their
            productivity</p>
        </div>
      </div>
    </div>
    <div class="col-md-4 mt-3">
      <div class="card shadow">
        <div class="card-body">
          <h5 class="card-title"> <mat-icon class="icon">eco</mat-icon>
            Sustainable</h5>
          <p class="card-text">With our sustainable solutions, we are reducing the environmental impact of daily
            office commute</p>
        </div>
      </div>
    </div>
  </div>


</div>

<!-- <br>
</div> -->


<div class="container mt-5">

  <div class="row">
    <div class="col-md-6">
      <p> <mat-icon>directions_car</mat-icon> COMPLIED VEHICLES</p>
      <p> <mat-icon>work</mat-icon> DEPLOYMENT</p>
      <p> <mat-icon>gps_fixed</mat-icon> <!-- Represents "GPRS TRACKING SYSTEM" -->
        <span>GPRS TRACKING SYSTEM</span>
      </p>
    </div>


    <div class="col-md-6">
      <p> <mat-icon>directions_car</mat-icon>
        <span>QUALITY VEHICLES</span>
      </p>
      <p> <mat-icon>people</mat-icon>
        <span>DEDICATED TRACKING TEAM & SUPERVISORS</span>
      </p>
      <p> <mat-icon>schedule</mat-icon>
        <span>ROSTERING AND DEPLOYMENT</span>
      </p>
    </div>
  </div>

</div>

<!-- ///////////////////// -->
<div class="elementor-widget-container mt-5" style="background-color: #EBF3FE;">
  <div class="container pt-5">
    <h2 class="CEO"><span style="color: #ff6600;">From The CEO's Desk </span></h2>

    <div class="row">
      <hr>
    
      <div class="col-md-2">
        <img width="200px" height="300px"
          src="https://res.cloudinary.com/nextgenedu/image/upload/v1703965762/MicrosoftTeams-image_1_i6wtpp.jpg"
          class="attachment-large size-large" alt="">  <h4><b>- Mr. Jay Nikat</b></h4>
      </div>

      <div class="col-md-10">
       
        <p>It is with great pleasure and enthusiasm that I extend my warmest greetings to each member of the Sahyadri
          family. As we continue our journey of innovation and excellence in the travel industry, I am thrilled to
          address you from the CEO's desk.</p>


        <p> Sahyadri Fleet Technology Solution has always been driven by a vision of transforming travel
          experiences and providing cutting-edge solutions. Our commitment to delivering quality services and
          embracing
          technological advancements has been the cornerstone of our success.</p>
        <p>In an ever-evolving landscape, our dedication to customer satisfaction remains unwavering. We understand
          that
          every journey is unique, and our mission is to ensure that each client experiences the utmost comfort,
          safety,
          and efficiency. At Sahyadri Fleet, we are not just a travel company; we are architects of memorable
          experiences.</p>
        <p>I am immensely proud of the dedication and hard work exhibited by our talented team. Together, we have
          overcome challenges and achieved milestones that have positioned Sahyadri as a leader in the industry.</p>
        <p>As we step into the future, let us continue to uphold the values that define Sahyadri Fleet Technology
          Solution – integrity, innovation, and a relentless pursuit of excellence. I am confident that, with
          our collective efforts, we will continue to set new benchmarks and redefine the standards of the travel
          industry.</p>
        <p>Thank you for being an integral part of the Sahyadri family. Your hard work, dedication, and passion are
          instrumental in our success. Together, let's embark on a journey of continued growth and prosperity.

        </p>
      </div>

    </div>
  </div>
  <br>
</div>
<!-- /////////////// -->

<div class="elementor-widget-container pt-5 " id="vtp">
  <h3 style="text-align: center;"> Vehicle Tracking System </h3>
  <p style="text-align: center;"> Vehicle Tracking System (VTS) encompasses an overabundance of operations
    and services that provide critical vehicular information like location,
    speed, mileage, as well as ignition status. </p>
  <div class="vtsSection">
  </div>
</div>
<!-- ////////////////// -->
<section class="challenges mt-5" id="challenges">
  <div class="container">
    <div class="row">
      <div class="col-md-8">
        <div class="challenges_left">
          <h3> <mat-icon>highlight</mat-icon>
            The Challenges </h3>
          <p> Known fact - Surviving in the cut-throat competition is the biggest
            challenge companies' face today. To keep business thriving in today's market,
            the only mantra is efficiency. So, along with striving to achieve the
            desired level of sophistication in fleet management, companies have to:
          </p>
          <ul>
            <p> <mat-icon>account_balance_wallet</mat-icon>
              Cost Containment</p>
            <p><mat-icon>check_circle</mat-icon> Confirm driver safety</p>
            <p> <mat-icon>leaderboard</mat-icon>
              Analyze drivers’ performance, and so much more. </p>
          </ul>
          <span> So, how do companies create a niche while juggling with other priorities on the list? </span>
        </div>

      </div>
      <div class="col-md-4">
        <div class="challenges_right">
          <img  src="https://res.cloudinary.com/nextgenedu/image/upload/v1704280620/images_2_jx28hd.jpg">
        </div>
      </div>
    </div>
  </div>
</section>
<!-- ///////////////////////// -->

<section class="blue_solutions mt-5">
  <div class="blue_solutions_pic p-5">
    <div class="container">
      <h3 style="color: white;"> The Solution </h3>
      <p style="color: white;"> Faster innovation and automation in your business by leveraging our
        end-to-end, efficient, and intelligent VTS solution to streamline fleet
        maintenance, reduce fleet expenses and operating costs, and
        improve fleet utilization.
      </p>
    </div>
  </div>
</section>
<!-- ///////////// -->


<!-- /////////////// -->
<div class="container mt-3">
  <div class="text-center">
    <h3 class="solutn_offers_title"> What do we <span> offer? </span> </h3>
    <p class="text-center"> The salient features of our Vehicle Tracking System (VTS) are </p>
  </div>
  <div class="row">
    <div class="col-md-4">
      <div class="feature_round_pic">
        <img width="200px" src="https://res.cloudinary.com/nextgenedu/image/upload/v1704106231/vehical_1_kseljc.png"
          class="img-fluid">
      </div>
    </div>
    <div class="col-md-8">
      <div class="feature_round_details">
        <h3><mat-icon>gps_fixed</mat-icon>
          GPS Tracking </h3>
        <p> Locate and keep an eye on vehicles, 24*7, at just your fingertips
          even in the most rural areas having inconsistent network speed. Know
          when your vehicles are moving, parked, or idle - all in one single interface </p>
      </div>
    </div>
  </div>
</div>
<div class="container mt-2">
  <div class="row">
  
    <div class="col-md-8">
      <div class="feature_round_details">
        <h3><mat-icon>directions_car</mat-icon>
          Vehicle Status Monitoring </h3>
        <p> Avoid vehicle and equipment downtime with our intelligent VTS solution.
          Embedded with new-age, cutting-edge technologies, our VTS solution can track
          vehicle status in real-time, detect faults, notify the concerned team(if faults found),
          and allow them to take measures proactively. </p>
      </div>
    </div>
    <div class="col-md-4">
      <div class="feature_round_pic">
        <img width="200px" src="https://res.cloudinary.com/nextgenedu/image/upload/v1704106562/vehical_2_acopto.png"
          class="img-fluid">
      </div>
      <hr>
    </div>
  </div>
</div>


<div class="container mt-2">
  <div class="row ">
    <div class="col-md-4">
      <div class="feature_round_pic">
        <img width="200px" src="https://res.cloudinary.com/nextgenedu/image/upload/v1704106746/vehical_3_nv8zsi.png"
          class="img-fluid">
      </div>
    </div>
    <div class="col-md-8">
      <div class="feature_round_details">
        <h3><mat-icon>place</mat-icon>
          Geofencing </h3>
        <p> Increase operational efficiency, labor optimization, and most importantly,
          safety and security of drivers by creating virtual geographical boundaries
          called Geofences with our VTS solution. Get notified when your vehicles enter
          or leave the marked boundaries. </p>
      </div>
    </div>
    
  </div>
</div>
<div class="container mt-2">
  <div class="row">
    <div class="col-md-8">
      <div class="feature_round_details">
        <h3> <mat-icon>notifications</mat-icon>
          Alerts and Notifications </h3>
        <p> From over speeding to theft detection to rash driving to route deviation to
          drivers’ behavior tracking to excessive stoppage to vehicle idling to low GPS
          signal to the engine on/off, our VTS solution provides granular
          insights on fleet maintenance with various alert options. </p>
      </div>
    </div>
    <div class="col-md-4">
      <div class="feature_round_pic">
        <img width="200px" src="https://res.cloudinary.com/nextgenedu/image/upload/v1704108551/vehical_4_qgsxti.png"
          class="img-fluid">
      </div>
    </div>
  </div>
</div>

<div class="container mt-2">
  <div class="row">
    <div class="col-md-4">
      <div class="feature_round_pic">
        <img width="200px" src="https://res.cloudinary.com/nextgenedu/image/upload/v1704108699/vehical_5_zurx0g.png"
          class="img-fluid">
      </div>
    </div>
    <div class="col-md-8">
      <div class="feature_round_details">
        <h3><mat-icon>bar_chart</mat-icon>
          Analytics and Reports </h3>
        <p> Gain actionable, valuable business insights from data patterns and trends and
          get comprehensive, customized reports to drive continuous improvement across
          your business with our dynamic VTS solution. </p>
      </div>
    </div>
  </div>
</div>
<div class="container mt-2">
  <div class="row">


    <div class="col-md-8">
      <div class="feature_round_details">
        <h3> <mat-icon>directions_car</mat-icon>

          Transport Owner App </h3>
        <p> Enable your transport owners to manage fleet efficiently, streamline business
          operations, and save big at just their fingertips by delivering them a hassle-free
          mobile application with all-inclusive feature list: </p>
        <ul class="fea_ul">
          <mat-icon>schedule</mat-icon> Schedule your trip here!<br>
<mat-icon>description</mat-icon> Trip summary<br>
<mat-icon>insert_chart</mat-icon> Driver performance report<br>
<mat-icon>live_help</mat-icon> 24*7 Help and Support

        </ul>
      </div>
    </div>

    <div class="col-md-4">
      <div class="feature_round_pic">
        <img width="200px" src="https://res.cloudinary.com/nextgenedu/image/upload/v1704108770/vehical_6_zyxs7x.png"
          class="img-fluid">
      </div>
    </div>
  </div>
</div>

<div class="container mt-2">
  <div class="row">
    <div class="col-md-4">
      <div class="feature_round_pic">
        <img width="200px" src="https://res.cloudinary.com/nextgenedu/image/upload/v1704108906/vehical_7_ar0mgl.png"
          class="img-fluid">
      </div>
    </div>
    <div class="col-md-8">
      <div class="feature_round_details">
        <h3><mat-icon>smartphone</mat-icon>
          Driver Mobile App </h3>
        <p> Help your drivers perform work more efficiently and productively by providing
          them with a quality mobile application designed with the following exciting options </p>
        <ul class="fea_ul">
          <p><mat-icon>directions</mat-icon> Navigation planning </p>
          <p> <mat-icon>flight</mat-icon>Trip planning </p>
          <p><mat-icon>priority_high</mat-icon> SOS
            button </p>
          <p>    <mat-icon>history</mat-icon> Trip history </p>
        </ul>
      </div>
    </div>
  </div>
</div>

<div class="why_solutions_bg mt-5" style="background-color: #EBF3FE;">
  <div class="container">
    <div class="row">
      <div class="col-lg-6">
        <div class="why_inner_content m-5">
          <h3> Why Us? </h3>
          <p> Our VTS solution - the combination of intelligence, innovation, and automation -
            helps clients to achieve unprecedented levels of business efficiency, sophistication,
            and quality. We are committed to providing: </p>


        </div>

      </div>

    </div>
    <ul class="row">
      <div class="col-lg-6">
        <p><mat-icon>extension</mat-icon>End-to-End, Flexible Solution</p>
        <p><mat-icon>verified_user</mat-icon> Professional and Certified Personnel</p>
        <p><mat-icon>playlist_add_check</mat-icon> Hassle-free experience to stakeholders</p>
      </div>
      <div class="col-lg-6">
        <p><mat-icon>analytics</mat-icon> Detailed and customized analytics reports</p>
        <p><mat-icon>support</mat-icon> Round the clock Technical Support</p>
        <p><mat-icon>settings</mat-icon>Easy and complete Fleet Management System</p>
      </div>
    </ul>
  </div>

</div>
<!-- ///////////// -->
<div class="container mt-5" id="contactus">

  <p class="office-address" style="text-align: center;"><strong>Contact Us</strong></p>

  <div class="row">
    <div class="col-md-6 map-container">
      <iframe width="100%" height="300px" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"
        src="https://maps.google.com/maps?q=IT%20Tower%2C%20Rakshak%20Nagar%2C%20Kharadi%2C%20Pune%2C%20Maharashtra&amp;t=m&amp;z=10&amp;output=embed&amp;iwloc=near"
        title="IT Tower, Rakshak Nagar, Kharadi, Pune, Maharashtra"
        aria-label="IT Tower, Rakshak Nagar, Kharadi, Pune, Maharashtra"></iframe>
    </div>

    <div class="col-md-6 address-container">
      <p class="office-address"><strong>Office Address:</strong></p>
      <p>Choudhari Building, Shop No 1, Near IT Tower, Thite Nagar, Kharadi, Pune – 411014</p>
      <p class="email-address"><strong>Email:</strong></p>
      <p><a href="mailto:admin.sahyadritechsolutions.com">admin.sahyadritechsolutions.com</a></p>
      <p class="contact-number"><strong>Contact No:</strong> <a href="tel:9673310404">9673310404</a></p>
    </div>

  </div>
</div>

<!-- //////////////// -->

<!-- //////////// -->
<footer>
  <h6>Explore the world with Sahyadri Fleet – Where Every Journey is an Experience!</h6>
</footer>