import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '../services/common.service';
import { ApiCallService } from '../services/api-call.service';
import { json } from 'stream/consumers';
import { reduce, reject, result } from 'lodash';
import { Router } from '@angular/router';
import { EmailValidation, mobileValidation } from '../services/validations';
import { promise } from 'protractor';
import { resolve } from 'path';
import { error } from 'console';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {
  registrationForm: FormGroup;
  emailId: any;
  formData: any;
  selectedFile: File | null = null;
  imagePreview: string | ArrayBuffer | null = null;
  constructor(public fb: FormBuilder, private commanService: CommonService, private apiService: ApiCallService, private router: Router) {
    this.registrationForm = this.fb.group({
      fullname: [null, [Validators.required]],
      image: [null],
      email: [null, [Validators.required, EmailValidation]],
      contact: [null, [Validators.required, mobileValidation]],
      address: [null, [Validators.required]],
      password: [null, [Validators.required, Validators.minLength(6)]],
      confirmPassword: [null, [Validators.required]],
      published: true
    },)

  }


  ngOnInit(): void {
  }


  selectFiles(event: any): void {
    const file = event.target.files[0];
    this.selectedFile = file;
    console.log("Json////////", this.selectedFile)
    // read and display the image preview
    if(this.selectedFile){
      const reader = new FileReader()
      reader.onload = (e:any) =>{
        this.imagePreview = e.target.result;
      };
      console.log("Json////////",reader)

      reader.readAsDataURL(this.selectedFile)
    }
    // Convert file to base64
      // reader.readAsDataURL(this.selectedFiles[i]);

    //   this.selectedFileNames = this.selectedFiles[i].name;
  }


  // Function to convert File to base64 string
  getBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = error => reject(error);
    })
  }

  convertToLowerCase(): void {
    this.emailId = this.emailId.toLowerCase();
  }

  submitregistrationForm() {
    if (!this.selectedFile) {
      console.error('No file selected.');
      return;
    }
    if (this.registrationForm.valid) {
      this.getBase64(this.selectedFile).then((base64Image) => {
        this.formData = {
          image: base64Image,
          fullname: this.registrationForm.get('fullname')?.value,
          email: this.registrationForm.get('email')?.value,
          contact: this.registrationForm.get('contact')?.value,
          address: this.registrationForm.get('address')?.value,
          password: this.registrationForm.get('password')?.value,
          confirmPassword: this.registrationForm.get('confirmPassword')?.value,
          published: this.registrationForm.get('published')?.value
        }
        console.log("formData////////", this.formData);
        var JSON = this.formData
        console.log("Json////////", JSON.image)
        let Api: any = this.apiService.registration;
        // var head = {}
        var data = { data: JSON }
        console.log("data////////", data);
        Api(data).subscribe({
          next: async (result: any) => {
            if (result) {
              this.commanService.showSuccess("Registration Succefully!")
              this.router.navigate(['login']);
            } else {
              this.commanService.showError("All fields required");
            }
            // if(result)
          }, error: (error: any) => {
            this.commanService.showError(error);
          }
        })
      })



      // const formData = new FormData();
      // formData.append('image',this.selectedFile)




    }


  }




}
