import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';
import { throwError } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class CommonService {
    loginUser(loginData: { email: any; password: any; }) {
      throw new Error('Method not implemented.');
    }
    dataTable: any;
    private baseUrl = environment.baseUrl;
    headers: any;
    constructor(private router: Router, private toastr: ToastrService, private http: HttpClient,) {
        this.headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' });
    }

    // Get API
    doGet(url: any, qp: any) {
        console.log(url, qp)
        return this.http.get(this.baseUrl + url, { params: qp.param, headers: this.headers, responseType: "text" })
            .pipe(map(this.extractData), catchError(this.handleErrorObservable));
    }

    // Post API
    doPost(url: any, data: any) {
        return this.http.post(this.baseUrl + url, data.data, { params: data.param, headers: this.headers, responseType: "text" })
            .pipe(map(this.extractData), catchError(this.handleErrorObservable));
    }

    // Post Method for Files(.xlsx, .csv)
    doPostFile(url: any, data: any) {
        return this.http.post(this.baseUrl + url, data.data, { params: data.param, headers: this.headers, responseType: "text" })
            .pipe(map(this.extractData), catchError(this.handleErrorObservable));
    }

    // Put API
    doPut(url: any, data: any) {
        return this.http.put(this.baseUrl + url, data.data, { params: data.param, headers: this.headers, responseType: "text" })
            .pipe(map(this.extractData), catchError(this.handleErrorObservable));
    }

    // Delete API
    doDelete(url: any, data: any) {
        return this.http
            .delete(this.baseUrl + url, { params: data.param, headers: this.headers, responseType: "text" })
            .pipe(map(this.extractData), catchError(this.handleErrorObservable));
    }

    // Response API
    private extractData(res: Response | any) {
        var body = res;
        try {
            return JSON.parse(body);
        } catch (e) {
            return { data: body };
        }
    }
    // Error Handaler API
   // Error Handaler API
   private handleErrorObservable(error: Response | any) {
    console.log(error)
    if ((error.status >= 400 && error.status < 500) || error.status === 0) {
        let erroroon = error.message;
        return throwError(() => new Error(erroroon));
    }

    else
        if (error.status === 500 || error.status === 600) {
            error.status = JSON.stringify(error.status);
            let erroroon = error.status + " " + "Contact to help Desk" + " " + error.error;
            return throwError(() => new Error(erroroon));
        }

        else {
            error.status = JSON.stringify(error.status);
            let erroroon = error.status + " " + error.error;
            return throwError(() => new Error(erroroon));
        }
}

    // Success Toastr
    showSuccess(msg: any) {
        this.toastr.success(msg, "Success!", {
            progressBar: true,
            progressAnimation: "decreasing",
            closeButton: true
        })
    }

    // Error Toastr
    showError(msg: any) {
        this.toastr.error(msg, "Error!", {
            progressBar: true,
            progressAnimation: "decreasing",
            closeButton: true
        })
    }



    // Logout User and expired session.
    logout() {
        sessionStorage.removeItem('token');
        this.router.navigate(['login']);
    }





    // ***************************** Common Function ********************************* //

// Remove whitespace and Datasource defaults to lowercase matches
    removeWhitespaceFilter(filterValue: string) {
        return new Promise((resolve, reject) => {
            filterValue = filterValue.trim(); // Remove whitespace
            filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
            resolve(filterValue)
        })
    }
    // Filter only deleted items
    async filterIsDeleted(data: any) {
        return new Promise((resolve, reject) => {
            resolve(data.filter((obj: any) => obj.isDeleted == false));
        })
    }
    // Filter only empty or null items
    async filterIsEmpltyElement(data: any) {
        return new Promise((resolve, reject) => {
            resolve(data.filter((obj: any) => obj != null));
        })
    }
    // Filter BOQ List
    async getFilterBOQ(data: any) {
        var arr: any = [];
        return new Promise((resolve, reject) => {
            data.forEach((element: any) => {
                console.log({ cmpCode: element[0].cmpCode, plantCode: element[0].plantCode, projCode: element[0].projCode })
                arr.push({ cmpCode: element[0].cmpCode, plantCode: element[0].plantCode, projCode: element[0].projCode })
            });
            resolve(arr);
        })
    }
    // Filter only is qty or is unit  items
    async filterActSubSeq(data: any) {
        return new Promise((resolve, reject) => {
            resolve(data.filter((obj: any) => obj.quntity != null && obj.displayUnit != null || !obj.displayUnit && obj.quntity!));
        })
    }


    // Sort Material
    async sortMaterial(data: any) {
        var arr: any = [];
        return new Promise((resolve, reject) => {
            data.forEach((element: any) => {
                if (element.subMaterial.length > 0) {
                    element.subMaterial.forEach((element1: any) => {
                        if (element1.materialDetails.length > 0) {
                            element1.materialDetails.forEach((element2: any) => {
                                arr.push({
                                    cmpCode: element2.cmpCode,
                                    descripption: element2.descripption,
                                    isDeleted: element2.isDeleted,
                                    materialDetailID: element2.materialDetailID,
                                    materialID: element2.materialID,
                                    materialSubID: element2.materialSubID,
                                })
                            });
                        }
                    });
                }
            });
            resolve(arr);
        })
    }
    // Sort Labour
    async sortLabour(data: any) {
        var arr: any = [];
        return new Promise((resolve, reject) => {
            data.forEach((element: any) => {
                if (element.labourDetails.length > 0) {
                    element.labourDetails.forEach((element1: any) => {
                        arr.push({
                            cmpCode: element1.cmpCode,
                            descripption: element1.descripption,
                            isDeleted: element1.isDeleted,
                            labourDetailsID: element1.labourDetailsID,
                            labourID: element1.labourID
                        })
                    });
                }
            });
            resolve(arr);
        })
    }

    // Sort Apply Supply
    async sortApplySupply(data: any) {
        var arr: any = [];
        return new Promise((resolve, reject) => {
            data.forEach((element: any) => {
                if (element.applySupplyDetails.length > 0) {
                    element.applySupplyDetails.forEach((element1: any) => {
                        console.log(element1)
                        arr.push({
                            applySupplyDetailsID: element1.applySupplyDetailsID,
                            applySupplyID: element1.applySupplyID,
                            cmpCode: element1.cmpCode,
                            descripption: element1.descripption,
                            isDeleted: element1.isDeleted
                        })
                    });
                }
            });
            resolve(arr);
        })
    }

    // Create JSON for Excel View and Download.
    async getExcelJSONList(data: any) {
        var arr: any = [];
        var actGroupId = "";
        var actSeqNo = "";
        var displaySequence = "";
        var actDesc = "";
        var displayUnit = ""
        var quntity = "";
        var rate = "";
        var amount: any = "";
        return new Promise((resolve, reject) => {
            data.forEach((element: any) => {
                actGroupId = ""; actSeqNo = ""; displaySequence = ""; actDesc = ""; displayUnit = ""; quntity = ""; rate = ""; amount = "";
                if (element.actGroupId) { actGroupId = element.actGroupId }
                if (element.actSeqNo) { actSeqNo = element.actSeqNo }
                if (element.displaySequence) { displaySequence = element.displaySequence }
                if (element.actGroupDesc) { actDesc = element.actGroupDesc }
                if (element.displayUnit) { displayUnit = element.displayUnit }
                if (element.quntity && element.rate) { quntity = element.quntity; rate = element.rate; amount = element.quntity * element.rate }
                arr.push({
                    "actGroupId": actGroupId,
                    "actSeqNo": actSeqNo,
                    "displaySequence": displaySequence,
                    "actDesc": actDesc,
                    "displayUnit": displayUnit,
                    "quntity": quntity,
                    "rate": rate,
                    "amount": amount
                })
                if (element.details.length > 0) {
                    element.details.forEach((detail: any) => {
                        actGroupId = ""; actSeqNo = ""; displaySequence = ""; actDesc = ""; displayUnit = ""; quntity = ""; rate = ""; amount = "";
                        if (detail.actGroupId) { actGroupId = detail.actGroupId }
                        if (detail.actSeqNo) { actSeqNo = detail.actSeqNo }
                        if (detail.displaySequence) { displaySequence = detail.displaySequence }
                        if (detail.actDesc) { actDesc = detail.actDesc }
                        if (detail.displayUnit) { displayUnit = detail.displayUnit }
                        if (detail.quntity && detail.rate) { quntity = detail.quntity; rate = detail.rate; amount = detail.quntity * detail.rate }
                        arr.push({
                            "actGroupId": actGroupId,
                            "actSeqNo": actSeqNo,
                            "displaySequence": displaySequence,
                            "actDesc": actDesc,
                            "displayUnit": displayUnit,
                            "quntity": quntity,
                            "rate": rate,
                            "amount": amount
                        })
                    })
                }
            });
            resolve(arr);
        })
    }

}
