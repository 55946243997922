import { Platform } from '@angular/cdk/platform';
import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  isNavbarOpen = false;
  isAndroid: any;

  constructor(private platform: Platform) {}

  ngOnInit() {
    this.isAndroid = this.platform.ANDROID;
  }

  toggleNavbar() {
    this.isNavbarOpen = !this.isNavbarOpen;
  }



}
