<div class="container">


    <mat-card class="col-md-4 mat-card-login">
        <img class="img-login-logo"[routerLink]="['/home']" width="100px" src="https://res.cloudinary.com/nextgenedu/image/upload/v1704112095/Sahyadri_logo_b4h5bt.png">
        <mat-divider></mat-divider>
        <mat-card-title class="mt-4 text-center">Sign UP your account</mat-card-title>
        <mat-card-content>
            <form *ngIf="registrationForm" [formGroup]="registrationForm" (ngSubmit)="submitregistrationForm()">
                <div class="row">
                    <mat-form-field>
                        <span matPrefix><mat-icon
                                class="input-prefix-mat-icon">perm_identity</mat-icon>&nbsp;&nbsp;</span>
                        <input required type="text" matInput placeholder="Full Name" formControlName="fullname" />
                        <mat-error>Please provide your full name</mat-error>
                    </mat-form-field>
                    <mat-form-field>
                        <input matInput placeholder="Select Images" readonly
                            [value]="selectedFileNames ? selectedFileNames : ''" (click)="removefile()" />

                        <input type="file" id="fileInput"  name="fileInput" accept="image/*" [multiple]="false"
                            (change)="selectFiles($event)" />

                        <button mat-icon-button matSuffix *ngIf="previews && isCreate==0 || isCreate==1"
                            matTooltip="Remove selected file" [matTooltipPosition]="'above'" type="button" color="warn">
                            <mat-icon (click)="removefile()">close</mat-icon>
                        </button>
                    </mat-form-field>
                    <div class="col-md-3 form-group">
                        <img *ngIf="imagePreview" width="50px" [src]="imagePreview" class="imagePreview">
                    </div>
                    <mat-form-field>
                        <span matPrefix><mat-icon class="input-prefix-mat-icon">mail_outline</mat-icon>
                            &nbsp;&nbsp;</span>
                        <input required type="text" matInput placeholder="Email ID" [(ngModel)]="emailId"
                            (input)="convertToLowerCase()" formControlName="email" />
                        <mat-error>Please provide a valid username</mat-error>
                    </mat-form-field>

                    <mat-form-field>
                        <span matPrefix><mat-icon class="input-prefix-mat-icon">phone</mat-icon>&nbsp;&nbsp;</span>
                        <input required type="tel" matInput placeholder="Contact" formControlName="contact" />
                        <mat-error>Please provide a valid contact number</mat-error>
                    </mat-form-field>

                    <mat-form-field>
                        <span matPrefix><mat-icon
                                class="input-prefix-mat-icon">location_on</mat-icon>&nbsp;&nbsp;</span>
                        <input required type="text" matInput placeholder="Address" formControlName="address" />
                        <mat-error>Please provide your address</mat-error>
                    </mat-form-field>
                    <mat-form-field>
                        <span matPrefix><mat-icon class="input-prefix-mat-icon">lock_outline</mat-icon>
                            &nbsp;&nbsp;</span>
                        <input type="password" [type]="hide ? 'password' : 'text'" matInput placeholder="Password"
                            formControlName="password" />
                        <button style="font-size: 18px;" mat-icon-button matSuffix (click)="hide = !hide"
                            [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                            <mat-icon class="input-prefix-mat-icon">{{hide ? 'visibility_off' :
                                'visibility'}}</mat-icon>
                        </button>
                        <mat-error>Please provide a valid password</mat-error>
                    </mat-form-field>

                    <mat-form-field>
                        <span matPrefix><mat-icon
                                class="input-prefix-mat-icon">lock_outline</mat-icon>&nbsp;&nbsp;</span>
                        <input type="password" [type]="hide ? 'password' : 'text'" matInput
                            placeholder="Confirm Password" formControlName="confirmPassword" />
                        <button style="font-size: 18px;" mat-icon-button matSuffix (click)="hide = !hide"
                            [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                            <mat-icon class="input-prefix-mat-icon">{{hide ? 'visibility_off' :
                                'visibility'}}</mat-icon>
                        </button>
                        <mat-error>Passwords do not match</mat-error>
                    </mat-form-field>
                </div>
                <div class="row mt-3">
                    <div class="col-md-6">
                        <a [routerLink]="['/login']" class="btn btn-link">Login</a>

                    </div>
                    <div class="col-md-6">
                        <button class="mat-button-gradient" style="float: right;" type="submit" mat-button>Sign
                            UP</button>
                    </div>
                </div>
            </form>
        </mat-card-content>
    </mat-card>


</div>