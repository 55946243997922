import {Validators } from "@angular/forms";
export const ZipValidation = Validators.pattern(/^[1-9][0-9]{5}$/);
export const mobileValidation = Validators.pattern(/^(\+\d{1,3}[- ]?)?\d{10}$/);
export const panValidation = Validators.pattern(/^[A-Z0-9]{10}$/);
export const gstinValidation = Validators.pattern(/^\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}$/);
export const EmailValidation = Validators.pattern(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/);
export const CINValidation = Validators.pattern(/^([LUu]{1})([0-9]{5})([A-Za-z]{2})([0-9]{4})([A-Za-z]{3})([0-9]{6})$/);
export const Accountnumber = Validators.pattern(/^[0-9]{9,18}$/); 
 export const IFSCValidation = Validators.pattern(/^[A-Z]{4}0[A-Z0-9]{6}$/);
// export const UsernameValidation = Validators.pattern(/*some regex*/);
export const ValidWithParam = (param: number) => {
  return Validators.pattern('/\d' + param + '/g');
 
}


  // emailregex: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
