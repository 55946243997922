import { Injectable } from '@angular/core';
import { CommonService } from './common.service';
import { data } from 'jquery';

@Injectable({
  providedIn: 'root'
})
export class ApiCallService {

  constructor(private commonService: CommonService) { }

  get WindowRef() { return window }
  // All Masters

  getCountry = (data: any) => this.commonService.doGet('getCountry', data);

  getState = (data: any) => this.commonService.doGet('getState', data);
  getStateId = (data: any) => this.commonService.doGet('State' + '/' + data.countryCode, data);



  getGPSDATA = (data: any) => this.commonService.doGet('getGPSDATA', data);
  getGPS = (data: any) => this.commonService.doGet('getGPS', data);
  deleteGPSData = (data:any) => this.commonService.doDelete('deleteGPSData'+ '/' + data.head.gpsID,data);
  loginUser = (data: any) => this.commonService.doGet('loginUser', data);
  registration = (data: any) => this.commonService.doPost('addUser', data);
  loginEmp= (data: any) => this.commonService.doGet('employeeLogin', data);

  addGPSDATA = (data: any) => this.commonService.doPost('addGPSData', data);
  getGPSDataR = (data: any) => this.commonService.doGet('getGPSDataR', data);
  updateGPSData = (data:any) => this.commonService.doPut('updateGPSData'+ '/' + data.head.gpsID,data);
  gpsSingleData = (data:any) => this.commonService.doGet('gpsSingleData?',data);


  addVehicle = (data: any) => this.commonService.doPost('addVehicle', data);
  getVehicles = (data:any) => this.commonService.doGet('getVehicles',data);
  updateVehicle = (data:any) => this.commonService.doPut('updateVehicle'+ '/' + data.head.vCode,data);
  deleteVehicle  = (data:any) => this.commonService.doDelete('deleteVehicle'+ '/' + data.head.vCode,data);

  addDriver = (data: any) => this.commonService.doPost('addDriver', data);
  getDriver = (data: any) => this.commonService.doGet('getDriver', data);
  updateDriver = (data:any) => this.commonService.doPut('updateDriver'+ '/' + data.head.dCode,data);
  deleteDriver  = (data:any) => this.commonService.doDelete('deleteDriver'+ '/' + data.head.dCode,data);
  

  addCompany = (data: any) => this.commonService.doPost('addCompany', data);
  getCompany = (data: any) => this.commonService.doGet('getCompany', data);
  updateCompany = (data:any) => this.commonService.doPut('updateCompany'+ '/' + data.head.company_id,data);
  deleteCompany  = (data:any) => this.commonService.doDelete('deleteCompany'+ '/' + data.head.company_id,data);

  addEmployee = (data: any) => this.commonService.doPost('addEmployee', data);
  getEmployees = (data: any) => this.commonService.doGet('employees' + '/' + data.head.company_id,data);
  updateEmployee = (data:any) => this.commonService.doPut('updateEmployee'+ '/' + data.head.employee_id,data);
  deleteEmployee  = (data:any) => this.commonService.doDelete('deleteEmployee'+ '/' + data.head.employee_id,data);

  addSchedule = (data: any) => this.commonService.doPost('createSchedule', data);
  getSchedules = (data: any) => this.commonService.doGet('getSchedules' + '/' + data.head.company_id,data);

  getAccess = (data: any) => this.commonService.doGet('getAccess', data);
  getRole  = (data: any) => this.commonService.doGet('getRole', data);
  addPickUp = (data: any) => this.commonService.doPost('addPickUp', data);
  getPickUp = (data:any) => this.commonService.doGet('pickups/first-in-last-out?', data)
  getPickUpAll = (data:any) => this.commonService.doGet('pickups/getInAndOutTimes?', data)

}


